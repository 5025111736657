import getFormData from "@/utils/convertToFormData"
import axios from "@/utils/axios.js"

export default {
  setGroupedElements({ commit }, data) {
    commit("SET_GROUPED_ELEMENTS", data)
  },
  setContentCreators({ commit }, data) {
    commit("SET_CONTENT_CREATORS", data)
  },
  setGroupedElements2({ commit }, data) {
    commit("SET_GROUPED_ELEMENTS2", data)
  },
  setGroupedElements3({ commit }, data) {
    commit("SET_GROUPED_ELEMENTS3", data)
  },
  updateElement({ commit }, data) {
    commit("UPDATE_ITEM", data)
  },
  getURLMetaData({ commit }, { payload, post }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .get("/post/getMeta", getFormData(payload), {
          headers: {
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token")
          }
        })
        .then(async ({ data: response }) => {
          if (response.data) {
            let altered_post = post
            altered_post.total_comments++

            await axios
              .get(`/post_comment/${response.data.id}`, {
                headers: {
                  Authorization:
                    "Bearer " + window.localStorage.getItem("access_token")
                }
              })
              .then(({ data: responsex }) => {
                if (responsex.code === 200) {
                  altered_post.comments.push(responsex.data)
                  commit("UPDATE_ITEM", altered_post)
                  resolve(responsex)
                }
              })
              .catch((err1) => {
                reject(err1)
              })
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addComment({ commit }, { payload, post }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post("/post_comment/save", getFormData(payload), {
          headers: {
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token")
          }
        })
        .then(async ({ data: response }) => {
          if (response.data) {
            let altered_post = post
            altered_post.total_comments++

            await axios
              .get(`/post_comment/${response.data.id}`, {
                headers: {
                  Authorization:
                    "Bearer " + window.localStorage.getItem("access_token")
                }
              })
              .then(({ data: responsex }) => {
                if (responsex.code === 200) {
                  altered_post.comments.push(responsex.data)
                  commit("UPDATE_ITEM", altered_post)
                  resolve(responsex)
                }
              })
              .catch((err1) => {
                reject(err1)
              })
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setComments({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_COMMENTS", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  deleteComment({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("DELETE_COMMENT", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setLoadNewPosts({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_LOAD_NEW_POSTS", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setSuggestedVideos({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_SUGGESTED_VIDEOS", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setResumableVideo({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_RESUMABLE_VIDEO", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  changeProgressResumableVideo({ commit }, progress) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("CHANGE_PROGRESS_RESUMABLE_VIDEO", progress)
        resolve(progress)
      } catch (error) {
        reject(error)
      }
    })
  }
}
